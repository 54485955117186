<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Edit Activity Type
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="activityTypeEditForm"
        #default="{invalid}"
      >
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Type ID*"
                label-for="h-activity-type-typeID"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Type ID"
                  vid="typeID"
                  rules="required"
                >
                  <b-form-input
                    id="h-activity-type-typeID"
                    v-model="typeID"
                    placeholder="Type ID"
                    :state="(errors.length > 0 || typeIDValidation) ? false : null"
                    name="typeID"
                    readonly
                    @input="typeIDValidation == true ? typeIDValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeIDValidation"
                    class="text-danger"
                  >
                    {{ typeIDError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-activity-type-status"
                  label-cols-md="5"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-activity-type-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Activity Type Name*"
                label-for="h-activity-type-typeName"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Activity Type"
                  vid="typeName"
                  rules="required"
                >
                  <b-form-input
                    id="h-activity-type-typeName"
                    v-model="typeName"
                    placeholder="Activity Type Name"
                    :state="(errors.length > 0 || typeNameValidation) ? false : null"
                    name="typeName"
                    @input="typeNameValidation == true ? typeNameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeNameValidation"
                    class="text-danger"
                  >
                    {{ typeNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Show In Portal"
                vid="customerBookable"
                rules="required"
              >
                <b-form-group
                  label="Show In Portal *"
                  label-for="h-activity-type-customerBookable"
                  label-cols-md="5"
                  :state="(errors.length > 0 || customerBookableValidation) ? false : null"
                >
                  <v-select
                    id="h-activity-type-customerBookable"
                    v-model="customerBookable"
                    label="title"
                    :options="trueFalseOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="customerBookableValidation == true ? customerBookableValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerBookableValidation"
                    class="text-danger"
                  >
                    {{ customerBookableError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Chinese Title"
                label-for="h-activity-type-typeNameSG"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Chinese Title"
                  vid="typeNameSG"
                  rules=""
                >
                  <b-form-input
                    id="h-activity-type-typeNameSG"
                    v-model="typeNameSG"
                    placeholder="Activity Type Name Chinese"
                    :state="(errors.length > 0 || typeNameSGValidation) ? false : null"
                    name="typeNameSG"
                    @input="typeNameSGValidation == true ? typeNameSGValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeNameSGValidation"
                    class="text-danger"
                  >
                    {{ typeNameSGError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Customer Only"
                vid="prospectBookable"
                rules="required"
              >
                <b-form-group
                  label="Customer Only *"
                  label-for="h-activity-type-prospectBookable"
                  label-cols-md="5"
                  :state="(errors.length > 0 || prospectBookableValidation) ? false : null"
                >
                  <v-select
                    id="h-activity-type-prospectBookable"
                    v-model="prospectBookable"
                    label="title"
                    :options="trueFalseOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="prospectBookableValidation == true ? prospectBookableValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="prospectBookableValidation"
                    class="text-danger"
                  >
                    {{ prospectBookableError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Location"
                label-for="h-activity-type-location"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location"
                  vid="location"
                  rules=""
                >
                  <b-form-input
                    id="h-activity-type-location"
                    v-model="location"
                    placeholder="Location"
                    :state="(errors.length > 0 || locationValidation) ? false : null"
                    name="location"
                    @input="locationValidation == true ? locationValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="locationValidation"
                    class="text-danger"
                  >
                    {{ locationError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Color"
                vid="color"
                rules="required"
              >
                <b-form-group
                  label="Color Scheme*"
                  label-for="h-activity-type-color"
                  label-cols-md="5"
                  :state="(errors.length > 0 || colorValidation) ? false : null"
                >
                  <!-- <v-select
                    id="h-activity-type-color"
                    v-model="color"
                    label="title"
                    :options="colorOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="colorValidation == true ? colorValidation = false : null"
                  >
                    <template #option="{ code, title }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${code}`"
                        style="height:10px;width:10px"
                      />
                      <span> {{ title }}</span>
                    </template>

                    <template #selected-option="{ code, title }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${code}`"
                        style="height:10px;width:10px"
                      />
                      <span> {{ title }}</span>
                    </template>
                  </v-select> -->

                  <b-form-input
                    id="h-activity-type-color"
                    v-model="color"
                    type="color"
                    :state="(errors.length > 0 || colorValidation) ? false : null"
                    name="color"
                    @input="colorValidation == true ? colorValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="colorValidation"
                    class="text-danger"
                  >
                    {{ colorError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label=""
                label-for="h-activity-type-locationSG"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Location Chinese"
                  vid="locationSG"
                  rules=""
                >
                  <b-form-input
                    id="h-activity-type-locationSG"
                    v-model="locationSG"
                    placeholder="Location Chinese"
                    :state="(errors.length > 0 || locationSGValidation) ? false : null"
                    name="locationSG"
                    @input="locationSGValidation == true ? locationSGValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="locationSGValidation"
                    class="text-danger"
                  >
                    {{ locationSGError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6">
              <b-form-group
                label="Timeslot Booking Title ( 中文标题 )"
                label-for="h-activity-type-timeSlotTitleSG"
                label-cols-md="5"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Timeslot Chinese Title"
                  vid="timeSlotTitleSG"
                  rules=""
                >
                  <b-form-input
                    id="h-activity-type-timeSlotTitleSG"
                    v-model="timeSlotTitleSG"
                    placeholder="Timeslot Booking Title ( 中文标题 )"
                    :state="(errors.length > 0 || timeSlotTitleSGValidation) ? false : null"
                    name="timeSlotTitleSG"
                    @input="timeSlotTitleSGValidation == true ? timeSlotTitleSGValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="timeSlotTitleSGValidation"
                    class="text-danger"
                  >
                    {{ timeSlotTitleSGError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Feature Image
            </h3>
          </template>
          <b-col
            cols="12"
          >
            <vue-dropzone
              id="event-images"
              ref="myVueDropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              @vdropzone-files-added="processFile"
              @vdropzone-removed-file="fileRemoved"
              @vdropzone-error="handleError"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Drop file here or click to upload
                </h3>
                <div class="subtitle">
                  The image should not bigger than 5 mb. JPEG or PNG only.
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mt-2"
                >
                  Upload file
                </b-button>
              </div>
            </vue-dropzone>
          </b-col>
        </b-card>

        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Custom Fields
            </h3>
          </template>
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
          >
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Component Type"
                vid="prospectBookable"
                rules="required"
              >
                <b-form-group
                  label="Component Type*"
                  :label-for="'h-activity-type-input-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.inputTypeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-activity-type-input-type' + index"
                    v-model="field.type"
                    label="title"
                    :options="inputTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="updateExtraFileds(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.inputTypeInvalid"
                    class="text-danger"
                  >
                    {{ field.inputTypeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />
            <b-col
              v-if="field.type == 'file'"
              cols="md-8"
            >
              <b-form-group
                label="Upload Image*"
                :label-for="'h-activity-type-file' + index"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Image"
                  vid="h-activity-type-file"
                  rules=""
                >
                  <b-form-file
                    :id="'h-activity-type-file' + index"
                    v-model="field.file"
                    accept="image/*"
                    @input="setImage(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.fileValidation"
                    class="text-danger"
                  >
                    {{ field.fileValidationMessage }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="field.type == 'file'"
              cols="md-4"
            >

              <b-img
                thumbnail
                fluid
                width="200px"
                height="auto"
                :src="field.base64File"
              />
            </b-col>

            <b-col cols="12">
              <div
                v-if="field.type == 'title-and-text'"
                class="row"
              >
                <b-col cols="md-6">
                  <b-form-group
                    label="Title*"
                    :label-for="'h-activity-type-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Title"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-activity-type-label' + index"
                        v-model="field.label"
                        placeholder="Title"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />

                <b-col cols="md-8">
                  <b-form-group
                    label="Text"
                    :label-for="'h-activity-type-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-activity-type-description' + index"
                      v-model="field.description"
                      placeholder="Text"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="md-4" />
              </div>
            </b-col>

            <b-col cols="12">
              <div
                v-if="field.type == 'short-answer' || field.type == 'long-answer' || field.type == 'single-select' || field.type == 'multi-select'"
                class="row"
              >
                <b-col cols="md-6">
                  <b-form-group
                    label="Label*"
                    :label-for="'h-activity-type-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <b-form-input
                        :id="'h-activity-type-label' + index"
                        v-model="field.label"
                        placeholder="Field Label"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
                <b-col cols="md-8">
                  <b-form-group
                    label="Description"
                    :label-for="'h-activity-type-description' + index"
                    label-cols-md="3"
                  >
                    <b-form-textarea
                      :id="'h-activity-type-description' + index"
                      v-model="field.description"
                      placeholder="Description"
                      rows="3"
                      name="description"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="md-4" />

                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                >
                  <b-form-group
                    label="Add Selection Option*"
                    :label-for="'h-activity-type-extraOptions' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      v-for="(opt, key) in field.extraOptions"
                      :key="key"
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <div class="inlineElement mb-2">
                        <b-form-input
                          :id="'h-activity-type-label' + index + 'extraOptionsKey' + key"
                          v-model="opt.key"
                          placeholder="Type an option Name"
                          :state="(errors.length > 0 || opt.keyValidation) ? false : null"
                          name="key"
                          class="mr-2"
                          @input="opt.keyValidation == true ? opt.keyValidation = false : null"
                        />
                        <b-form-input
                          :id="'h-activity-type-label' + index + 'extraOptions' + key"
                          v-model="opt.value"
                          placeholder="Add a text to display in Calendar Title"
                          :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                          name="value"
                          @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                        />
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-secondary"
                          type="button"
                          class="btn-icon rounded-circle"
                          @click="removeCustomFieldOptions(index, key)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            size="18"
                          />
                        </b-button>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                    <p
                      class="addOptionText"
                      @click="addCustomFieldOptions(index)"
                    >
                      Add an Option
                    </p>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                />

                <b-col cols="md-6">
                  <b-form-group
                    label="Display in Calendar Title*"
                    :label-for="'h-activity-type-displayInCalendar' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Display in Calendar Title"
                      vid="displayInCalendar"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-displayInCalendar' + index"
                        v-model="field.displayInCalendar"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.displayInCalendar === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.displayInCalendarInvalid"
                        class="text-danger"
                      >
                        {{ field.displayInCalendarInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />

                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                >
                  <b-form-group
                    label="User Can Select*"
                    :label-for="'h-activity-type-multiSelectType' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="User Can Select"
                      vid="multiSelectType"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-multiSelectType' + index"
                        v-model="field.multiSelectType"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in multiSelectOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.multiSelectType === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.multiSelectTypeInvalid"
                        class="text-danger"
                      >
                        {{ field.multiSelectTypeInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="field.type == 'multi-select'"
                  cols="md-6"
                />

                <b-col cols="md-6">
                  <b-form-group
                    label="Required?*"
                    :label-for="'h-activity-type-required' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Required"
                      vid="required"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-required' + index"
                        v-model="field.required"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.required === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.requiredInvalid"
                        class="text-danger"
                      >
                        {{ field.requiredInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-6" />
              </div>
            </b-col>

            <b-col cols="12">
              <p
                class="removeText"
                @click="removeCustomField(index)"
              >
                Remove this component
              </p>

              <hr class="dividerHR">
            </b-col>
          </b-row>
          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <span class="align-middle">Add Custom Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add Custom Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelEdit"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BFormRadioGroup, BForm, BButton, BNav, BFormFile, BImg, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BFormRadioGroup,
    BForm,
    BButton,
    BNav,
    BImg,
    BFormRadio,

    vSelect,
    vueDropzone: vue2Dropzone,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      typeID: '',
      typeName: '',
      typeNameSG: '',
      location: '',
      locationSG: '',
      timeSlotTitleSG: '',
      customerBookable: 'yes',
      prospectBookable: 'yes',
      status: 'published',
      color: '#e2c5c5',
      images: [],
      existingImages: '',
      typeIDError: 'Valid type ID is required',
      typeIDValidation: false,
      typeNameError: 'Valid activity type is required',
      typeNameValidation: false,
      typeNameSGError: 'Valid activity type is required',
      typeNameSGValidation: false,
      locationError: 'Valid location is required',
      locationValidation: false,
      locationSGError: 'Valid location is required',
      locationSGValidation: false,
      timeSlotTitleSGError: 'Valid title is required',
      timeSlotTitleSGValidation: false,
      customerBookableError: 'Valid bookable by customer is required',
      customerBookableValidation: false,
      prospectBookableError: 'Valid bookable by prospect is required',
      prospectBookableValidation: false,
      statusValidation: false,
      statusError: 'Valid status is required',
      colorValidation: false,
      colorError: 'Valid color is required',
      trueFalseOptions: [{ title: 'Yes', code: 'yes' }, { title: 'No', code: 'no' }],
      inputTypeOptions: [
        { title: 'Image', code: 'file' },
        { title: 'Title and Text', code: 'title-and-text' },
        { title: 'Short Answer Field', code: 'short-answer' },
        { title: 'Long Answer Field', code: 'long-answer' },
        { title: 'Selection List Field (Add-Ons)', code: 'multi-select' },
        { title: 'Single Checkbox Field', code: 'single-select' },
      ],
      customFields: [],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      multiSelectOptions: [
        { text: 'One Option Only', value: 'single' },
        { text: 'Multiple Options', value: 'multiple' },
      ],
      statusOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Draft', code: 'draft' },
      ],
      colorOptions: [
        { title: 'Primary', code: 'primary' },
        { title: 'Secondary', code: 'secondary' },
        { title: 'Info', code: 'info' },
        { title: 'Success', code: 'success' },
        { title: 'Warning', code: 'warning' },
        { title: 'Danger', code: 'danger' },
      ],

      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png',
        maxFilesize: 5,
        addRemoveLinks: true,
        maxFiles: 1,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
      },

      // validation rules
      required,
    }
  },
  created() {
    this.$http.get(`appointment/activity-types/${this.$route.params.id}`)
      .then(response => {
        this.typeID = response.data.typeID ?? ''
        this.typeName = response.data.typeName ?? ''
        this.typeNameSG = response.data.typeNameSG ?? ''
        this.location = response.data.location ?? ''
        this.locationSG = response.data.locationSG ?? ''
        this.timeSlotTitleSG = response.data.timeSlotTitleSG ?? ''
        this.customerBookable = response.data.customerBookable === true ? 'yes' : 'no'
        this.prospectBookable = response.data.prospectBookable === true ? 'yes' : 'no'
        this.status = response.data.status ?? 'published'
        this.color = response.data.color ?? 'primary'
        const file = { size: 500, name: 'Icon' }
        const url = response.data.imageURLs[0] || ''
        if (url) {
          this.existingImages = url
          this.$refs.myVueDropzone.manuallyAddFile(file, url)
        }
        const fullData = response.data.customFields.map(field => {
          const extraOptionsData = field.extraOptions.map(option => {
            const optionData = {
              key: option.key,
              keyInvalid: false,
              keyInvalidMessage: '',
              value: option.value,
              valueInvalid: false,
              valueInvalidMessage: '',
            }
            return optionData
          })

          const properties = {
            type: field.type,
            label: field.label,
            file: null,
            base64File: field.file,
            description: field.description,
            required: field.required,
            displayInCalendar: field.displayInCalendar,
            extraOptions: extraOptionsData,
            multiSelectType: field.multiSelectType,
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
            displayInCalendarInvalid: false,
            displayInCalendarInvalidMessage: '',
            multiSelectTypeInvalid: false,
            multiSelectTypeInvalidMessage: '',
          }
          return properties
        })

        this.customFields = fullData ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    processFile(file) {
      if (this.images.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'You can upload only one file',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        const acceptedTypes = ['image/jpeg', 'image/png']
        file.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'Image is too large',
              //     icon: 'AlertTriangleIcon',
              //     variant: 'danger',
              //   },
              // })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                this.images.push(event.target.result)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    fileRemoved(file) {
      if (file.manuallyAdded) {
        this.existingImages = ''
      }

      this.images = []
    },
    handleError(file, message) {
      this.$refs.myVueDropzone.removeFile(file)
      if (message !== 'Upload canceled.') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    addCustomField() {
      this.customFields.push({
        type: 'title-and-text',
        label: '',
        description: '',
        required: 'yes',
        displayInCalendar: 'yes',
        extraOptions: [],
        file: null,
        base64File: null,
        multiSelectType: 'single',
        typeInvalid: false,
        typeInvalidMessage: '',
        fileInvalid: false,
        fileInvalidMessage: '',
        labelInvalid: false,
        labelInvalidMessage: '',
        requiredInvalid: false,
        requiredInvalidMessage: '',
        displayInCalendarInvalid: false,
        displayInCalendarInvalidMessage: '',
        multiSelectTypeInvalid: false,
        multiSelectTypeInvalidMessage: '',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].inputTypeInvalid === true ? this.customFields[index].inputTypeInvalid = false : null
      if (this.customFields[index].type === 'multi-select') {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
        })
      } else {
        this.customFields[index].extraOptions = []
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Remove will <strong>permanently delete this component / custom field</strong>. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.customFields.splice(index, 1)
          }
        })
    },

    setImage(index) {
      const reader = new FileReader()
      reader.readAsDataURL(this.customFields[index].file)
      reader.onload = () => {
        this.customFields[index].base64File = reader.result
      }
    },
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'appointment-activity-types-index' })
          }
        })
    },
    submitForm() {
      this.$refs.activityTypeEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('typeID', this.typeID)
          formData.append('typeName', this.typeName)
          formData.append('typeNameSG', this.typeNameSG)
          formData.append('location', this.location)
          formData.append('locationSG', this.locationSG)
          formData.append('timeSlotTitleSG', this.timeSlotTitleSG)
          formData.append('color', this.color)
          formData.append('customerBookable', this.customerBookable)
          formData.append('prospectBookable', this.prospectBookable)
          formData.append('status', this.status)
          formData.append('existingImages', this.existingImages)
          if (this.images.length) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.images.length; i++) {
              formData.append('images[]', this.images[i])
            }
          } else {
            formData.append('images', '')
          }
          const trimemdData = this.customFields.map(field => {
            const extraOptionsData = field.extraOptions.map(option => {
              const optionData = {
                key: option.key,
                value: option.value,
              }
              return optionData
            })

            const properties = {
              type: field.type,
              file: field.base64File,
              label: field.label,
              description: field.description,
              required: field.required,
              displayInCalendar: field.displayInCalendar,
              multiSelectType: field.multiSelectType,
              extraOptions: extraOptionsData,
            }
            return properties
          })

          formData.append('customFields', JSON.stringify(trimemdData))

          this.$http.patch(`appointment/activity-types/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Changes have Saved',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-activity-types-show', params: { id: this.$route.params.id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'typeID') {
                    this.typeIDError = validationError.msg
                    this.typeIDValidation = true
                  } else if (validationError.param === 'typeName') {
                    this.typeNameError = validationError.msg
                    this.typeNameValidation = true
                  } else if (validationError.param === 'typeNameSG') {
                    this.typeNameSGError = validationError.msg
                    this.typeNameSGValidation = true
                  } else if (validationError.param === 'location') {
                    this.locationError = validationError.msg
                    this.locationValidation = true
                  } else if (validationError.param === 'locationSG') {
                    this.locationSGError = validationError.msg
                    this.locationSGValidation = true
                  } else if (validationError.param === 'timeSlotTitleSG') {
                    this.timeSlotTitleSGError = validationError.msg
                    this.timeSlotTitleSGValidation = true
                  } else if (validationError.param === 'customerBookable') {
                    this.customerBookableError = validationError.msg
                    this.customerBookableValidation = true
                  } else if (validationError.param === 'prospectBookable') {
                    this.prospectBookableError = validationError.msg
                    this.prospectBookableValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  } else if (validationError.param === 'color') {
                    this.colorError = validationError.msg
                    this.colorValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
</style>
